import React, { useState } from 'react'
import ActionWidget from '@components/ActionWidget/ActionWidget'
import { Box, Button } from '@revolut/ui-kit'
import MrtPopup from '@src/pages/Forms/RequisitionForm/Mrt/MrtPopup'
import { MRT_FORM } from '@src/constants/externalLinks'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { RequisitionInterface } from '@src/interfaces/requisitions'
import MrtSuccessPopup from '@src/pages/Forms/RequisitionForm/Mrt/MrtSuccessPopup'

const MrtWidget = () => {
  const { values, submit } = useLapeContext<RequisitionInterface>()
  const [openConfirmationPopup, setOpenConfirmationPopup] = useState(false)
  const [openSuccessPopup, setOpenSuccessPopup] = useState(false)
  const [loading, setLoading] = useState(false)

  const onClickConfirm = async () => {
    values.is_mrt_jira_ticket_url_confirmed = true
    setLoading(true)

    try {
      await submit()
    } catch (e) {
      values.is_mrt_jira_ticket_url_confirmed = false
    } finally {
      setOpenConfirmationPopup(false)
      setOpenSuccessPopup(true)
      setLoading(false)
    }
  }

  if (!values.is_mrt_jira_ticket_url_required) {
    return null
  }

  return (
    <Box>
      <MrtSuccessPopup
        open={openSuccessPopup}
        onClose={() => setOpenSuccessPopup(false)}
      />
      <MrtPopup
        open={openConfirmationPopup}
        onClose={() => setOpenConfirmationPopup(false)}
        onConfirm={onClickConfirm}
        loading={loading}
      />
      {!values.is_mrt_jira_ticket_url_confirmed && (
        <ActionWidget
          title="Complete MRT Questionnaire to enable approval of requisition"
          text="Requisitions for Lead or above seniorities require submission of the MRT questionnaire to comply with regulations. Non completion will lead to delays in the hiring process"
          data-testid="mrt-widget"
        >
          <Button
            use="a"
            href={MRT_FORM}
            target="_blank"
            rel="noopener noreferrer"
            size="sm"
            variant="secondary"
          >
            Complete questionnaire
          </Button>
          <Button
            size="sm"
            variant="secondary"
            onClick={() => setOpenConfirmationPopup(true)}
          >
            I have completed the MRT questionnaire for this requisition
          </Button>
        </ActionWidget>
      )}
    </Box>
  )
}

export default MrtWidget
