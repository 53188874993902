import React, { useMemo } from 'react'
import { CounterWidget, CounterWidgetSkeleton } from '@revolut/ui-kit'
import { CandidatesPipelineViewType } from '@src/api/recruitment/candidates'
import { InterviewRoundState } from '@src/interfaces/interviewTool'
import { JobPostingInterface } from '@src/interfaces/jobPosting'
import useCandidatePipelineData from '@src/pages/Forms/JobPosting/Components/useCandidatePipelineData'
import { FilterByInterface } from '@src/interfaces/data'

const getCommonFilters = (
  interviewRoundState: InterviewRoundState,
): FilterByInterface[] => {
  return [
    {
      columnName: 'active_interview_round__state',
      filters: [
        {
          id: interviewRoundState,
          name: interviewRoundState,
        },
      ],
    },
    {
      columnName: 'is_snoozed',
      filters: [{ id: 'False', name: 'False' }],
    },
  ]
}

const useCandidateCounts = (jobPostingId: number) => {
  const { stats: activeCandidatesStats, loading: loadingActiveCandidatesStats } =
    useCandidatePipelineData(jobPostingId, getCommonFilters(InterviewRoundState.active))
  const { stats: archivedCandidatesStats, loading: loadingArchivedCandidatesStats } =
    useCandidatePipelineData(jobPostingId, getCommonFilters(InterviewRoundState.archived))
  const counts = useMemo(() => {
    const findTotal = ({ stage_type }: CandidatesPipelineViewType) =>
      stage_type === 'Total'
    const active = activeCandidatesStats?.find(findTotal)?.count ?? 0
    const rejected = archivedCandidatesStats?.find(findTotal)?.count ?? 0
    const total = active + rejected
    return {
      total,
      rejected,
      active,
    }
  }, [activeCandidatesStats, archivedCandidatesStats])
  return {
    counts,
    loading: loadingActiveCandidatesStats || loadingArchivedCandidatesStats,
  }
}

type CandidateCountProps = {
  jobPosting: JobPostingInterface
}

const CandidateCount = ({ jobPosting }: CandidateCountProps) => {
  const { loading, counts } = useCandidateCounts(jobPosting.id)
  if (loading) {
    return (
      <CounterWidget>
        <CounterWidget.Column>
          <CounterWidgetSkeleton.Description />
          <CounterWidgetSkeleton.Subtitle />
        </CounterWidget.Column>
        <CounterWidget.Column>
          <CounterWidgetSkeleton.Description />
          <CounterWidgetSkeleton.Subtitle />
        </CounterWidget.Column>
        <CounterWidget.Column>
          <CounterWidgetSkeleton.Description />
          <CounterWidgetSkeleton.Subtitle />
        </CounterWidget.Column>
      </CounterWidget>
    )
  }
  return (
    <CounterWidget>
      <CounterWidget.Column>
        <CounterWidget.Description>{counts.total}</CounterWidget.Description>
        <CounterWidget.Subtitle textAlign="center">Total</CounterWidget.Subtitle>
      </CounterWidget.Column>
      <CounterWidget.Column>
        <CounterWidget.Description>{counts.active}</CounterWidget.Description>
        <CounterWidget.Subtitle textAlign="center">Active</CounterWidget.Subtitle>
      </CounterWidget.Column>
      <CounterWidget.Column>
        <CounterWidget.Description>{counts.rejected}</CounterWidget.Description>
        <CounterWidget.Subtitle textAlign="center">Rejected</CounterWidget.Subtitle>
      </CounterWidget.Column>
    </CounterWidget>
  )
}

export default CandidateCount
