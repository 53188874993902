import { useGetHiringPipelineByJobPostingId } from '@src/api/recruitment/hiringPipelines'
import { HiringPipelineInterface } from '@src/interfaces/hiringPipelines'
import { useGetCandidatesPipelineView } from '@src/api/recruitment/candidates'
import { FilterByInterface } from '@src/interfaces/data'

const getHiringPipelineId = (hiringPipelines?: HiringPipelineInterface[]) => {
  if (!hiringPipelines?.length || hiringPipelines?.length !== 1) {
    return null
  }
  return hiringPipelines[0].id
}

const getFilters = (hiringPipelineId: number | null, filters: FilterByInterface[]) => {
  if (!hiringPipelineId) {
    return null
  }
  return [
    ...filters,
    {
      columnName: 'active_hiring_pipeline',
      filters: [{ id: hiringPipelineId, name: String(hiringPipelineId) }],
    },
  ]
}

const useCandidatePipelineData = (
  jobPostingId: number,
  filters: FilterByInterface[] = [
    {
      columnName: 'origin',
      filters: [{ id: 'application', name: 'application' }],
    },
  ],
) => {
  const { data: hiringPipelines, isLoading: loadingHiringPipelines } =
    useGetHiringPipelineByJobPostingId(jobPostingId)
  const hiringPipelineId = getHiringPipelineId(hiringPipelines?.results)
  const { data: candidateStats, isLoading: loadingCandidateStats } =
    useGetCandidatesPipelineView(getFilters(hiringPipelineId, filters))
  return {
    hiringPipelineId,
    stats: candidateStats,
    loading: loadingHiringPipelines || loadingCandidateStats,
  }
}

export default useCandidatePipelineData
